/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-tabs */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PageInfo from '../components/PageInfo/PageInfo';
import ArticlePreview from '../components/ArticlePreview/ArticlePreview';

const siteMetadata = {
  title: 'Blog numizmatyczny - ciekawostki związane ze światem numizmatyki',
  description: 'Odkryj fascynujący świat numizmatyki! Blog dla kolekcjonerów monet, banknotów i medali – poznaj wartość monet, ich historię i tajemnice.',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/blog/',
  locale: 'pl_PL',
};

const BlogList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  /* gap: 50px 0; */
  gap: 15px 0;

  @media only screen and (min-width: 640px) {
    gap: 24px 25px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 1024px) {
    gap: 48px 24px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1200px) {
    gap: 70px 40px;
  }

  @media only screen and (max-width: 639px) {
    > li {
      border-top: 1px solid rgba(199,87,132,.25);
      padding: 25px 0 0;
    }
  }

  @media only screen and (min-width: 1024px) {
    > li {
      &:first-child {
        grid-column: 1 / -1;

        article {
          align-items: center;
          display: flex;
          justify-content: space-between;

          > a {
            display: flex;
            gap: 30px;
            width: 100%;

            .gatsby-image-wrapper {
              margin: 0;
              order: 1;
              width: 50%;
            }

            .article__desc {
              width: 50%;
            }
          }

          .article__desc {
            width: calc(100% - 540px);

            h3 {
              font-size: 3.2rem;
              max-width: 500px;
            }
          }
        }
      }
    }
  }
  
  .gatsby-image-wrapper {
    margin: 0 0 8px;
  }

  h2 {
    font-size: 2rem;
    margin: 0 0 10px;
    padding: 0;
  }

  p {
    margin: 0;
  }

  .date {
    font-size: 1.4rem;
    margin: 0 0 5px;
  }
`;

const PageData = {
  title: 'Blog numizmatyczny',
  paragraph: 'Witaj na naszym blogu! Znajdziesz tutaj artykuły, aktualne wiadomości i praktyczne porady dotyczące kolekcjonowania, inwestowania oraz historii numizmatyki. Niezależnie od tego, czy jesteś doświadczonym kolekcjonerem, czy dopiero zaczynasz swoją przygodę z numizmatyką, nasz blog dostarczy Ci&nbsp;wartościowych informacji i inspiracji.',
};

const ArticlesPage = ({ data }) => {
  const { allMdx: { nodes } } = data;
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'pl' }}>
        <title>{siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.description} />
        <meta property="og:locale" content={siteMetadata.locale} />
        <meta property="og:site_name" content={siteMetadata.siteName} />
        <meta property="og:image" content={siteMetadata.imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteMetadata.siteUrl} />
        <meta property="og:title" content={siteMetadata.title} />
        <meta property="og:description" content={siteMetadata.description} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={siteMetadata.title} />
        <meta name="twitter:description" content={siteMetadata.description} />
        <meta name="twitter:image" content={siteMetadata.imageUrl} />

        <link rel="canonical" href={siteMetadata.siteUrl} />
      </Helmet>
      <PageInfo title={PageData.title} paragraph={PageData.paragraph} />
      <BlogList>
        {nodes.map(({
          excerpt,
          frontmatter: {
            id,
            title,
            slug,
            date,
            featuredImage,
          },
        }) => (
          <li key={id}>
            <ArticlePreview
              title={title}
              date={date}
              excerpt={excerpt}
              image={featuredImage ? featuredImage.childImageSharp.fluid : null}
              slug={slug}
            />
          </li>
        ))}
      </BlogList>
    </>
  );
};

export const query = graphql`
  {
    allMdx(sort: { fields: [frontmatter___id], order: DESC }) {
      nodes {
        frontmatter {
          id
          title
          author
          date
          slug
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 360, maxHeight: 240) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        excerpt(pruneLength: 120)
      }
    }
  }
`;

export default ArticlesPage;
