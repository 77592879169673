/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import Lead from '../Common/Lead';

const Wrapper = styled.div`
  margin: 50px 0;
  max-width: 1100px;
`;

const PageInfo = ({ title, paragraph }) => (
  <Wrapper>
    <h1>{title}</h1>
    <Lead
      desc={paragraph}
    />
  </Wrapper>
);

export default PageInfo;
