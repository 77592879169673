/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Image from 'gatsby-image';

const Article = styled.article`
  h1 {
    margin: .5rem 0 2rem;
  }

  h3 {
    color: #5dd882;
    transition: color 200ms ease-in;
  }

  h3 {
    margin: 0 0 16px;
  }

  figure {
    height: 240px;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover,
    &:focus {
      h3,
      .article__more {
        color: white;
      }
    }
  }

  .article {
    &__date {
      font-weight: 600;
      color: #c8608b;
      font-size: 1.3rem;
      letter-spacing: 0.05em;
      margin: 0 0 4px;
      text-transform: uppercase;

      svg {
        margin: 0 9px 0 0;
        width: 13px;
        position: relative;
        top: 2px;

        path {
          fill: #c8608b;
        }
      }
    }

    &__lead {
      margin: 0 0 16px;
    }

    &__more {
    }
  }
`;

const StyledImage = styled(Image)`
  /* height: 400px;
  width: 500px; */
`;

const Preview = ({
  title, date, excerpt, slug, image,
}) => (
  <Article>
    <Link to={`/blog/${slug}`}>
      <StyledImage fluid={image} />
      <div className="article__desc">
        <p className="article__date">
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l352 0 0 256c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256z" /></svg> */}
          {date}
        </p>
        <h3 dangerouslySetInnerHTML={{ __html: title }} />
        <p className="article__lead">{excerpt}</p>
      </div>
    </Link>
  </Article>
);

export default Preview;
